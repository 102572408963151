<template>
  <div>
    <div class="container btmlayer">
      <div class="searcheng">
        <a v-bind:href="$root.$el.getAttribute('home_route')" class="nav-home-btn">
          <i class="icon icon-home"></i> <span>{{ $t('frontend.home') }}</span>
        </a>
        <form v-bind:action="$root.$el.getAttribute('product_list_route')" method="GET" class="searchbar">

          <select class="search-cat searchbar-margin" style="width:120px;" name="search_input_product_type">
            <option value="all">{{ $t('frontend.product_all') }}</option>
            <template v-for="(level1, index1) in product_types">
              <option v-bind:value="level1.id" class="product-type-level1 unselectable">
                <div v-html="level1.text"></div>
              </option>      
              <template v-for="(level2, index2) in level1.children">
                <!--If-->
                <template v-if="level2.children.length > 0">
                  <option v-bind:value="level2.id" class="product-type-level2 unselectable">----{{
                      level2.text
                    }}
                  </option>
                </template>
                <!--Else-->
                <option v-else v-bind:value="level2.id">----{{ level2.text }}</option>
              </template>        
            </template>
          </select>


          <div class="searchinput searchbar-margin searchtyping-area">
            <input id="algolia_product_search" type="text" name="search_input_product_name" autocomplete="off"
                   v-bind:placeholder="$t('frontend.input')" class="searchtyping">
            <div class="searchrecommend" style="width: 300px;">
              <div class="row m-1" v-for="item in search_result" :index="item.objectID"
                   @click="goToProductDetail(item.objectID)" style="cursor: pointer;">
                <img :src="item.url + item.item_photo" height="75px" width="75px">
                <div class="col" style="padding: 0 10px; height: 75px;">
                  <a v-if="locale === 'en'">{{ item.name_en }}</a>
                  <a v-if="locale === 'zh-hk'">{{ item.name_zh_hk }}</a>
                  <a v-if="locale === 'zh-cn'">{{ item.name_zh_cn }}</a>
                </div>
              </div>
              <div class="more_result" v-if="search_result.length >= 6" @click="goSearch()">
                <u>{{ $t('frontend.more_result') }}</u>
              </div>
              <!--<li class="hot">熱門搜尋</li>-->
              <!--<li><a href="">大亂鬥</a></li>-->
              <!--<li><a href="">銀河聯軍</a></li>-->
              <!--<li><a href="https://hk.yahoo.com">絆地獄</a></li>-->
            </div>
          </div>

          <button type="submit" class="btn btn-green-blue mr-2 searchbar-margin" id="search-submit-btn" ref="submit">
            <i class="icon icon-search"></i> <span>{{ $t('frontend.search') }}</span>
          </button>

          <a href="#" class="btn btn-river-blue search-adv-btn searchbar-margin d-none d-lg-block" id="search-adv-btn">
            <i class="icon icon-monster"></i> <span>{{ $t('frontend.advanced_search') }}</span>
          </a>
        </form>
      </div>
      <div class="memberfeature">
        <ul>
          <template v-if="storeIsLoggedIn === false">
            <!--<li><a v-bind:href="$root.$el.getAttribute('register_route')"><i class="icon icon-register"></i> <span>{{ $t('frontend.register') }}</span></a></li>-->
            <!--<li><a v-bind:href="$root.$el.getAttribute('login_route')"><i class="icon icon-login"></i> <span>{{ $t('frontend.login') }}</span></a></li>-->
            <li><a v-bind:href="$root.$el.getAttribute('login_route')"><i class="icon icon-login"></i>
              <span>{{ $t('frontend.register_and_login') }}</span></a></li>
            <li>
              <a v-bind:href="$root.$el.getAttribute('favourite_list_route')">
                <i class="icon icon-favourite"></i> <span>{{ $t('frontend.favourite_list') }}</span>
                <small v-if="storeFav.length > 0" class="number-indicator active">{{
                    $root.$store.getters.getFav.length
                  }}</small>
              </a>
            </li>
            <li>
              <a v-bind:href="$root.$el.getAttribute('cart_route')">
                <i class="icon icon-cart"></i>
                <span>{{ $t('frontend.cart') }}</span>
                <small v-if="storeCart.length > 0"
                       class="number-indicator active">{{ $root.$store.getters.getCart.length }}</small>
              </a>
            </li>
          </template>
          <template v-else>
            <li><a v-bind:href="$root.$el.getAttribute('member_page_route')" class="username"><i
                class="icon icon-login"></i>
              {{ storeGetUser.username }}</a></li>
            <li><a v-bind:href="$root.$el.getAttribute('logout_route')" class="username"><i
                class="icon icon-logout"></i>{{ $t('frontend.logout') }}</a></li>
            <li>
              <a v-bind:href="$root.$el.getAttribute('member_favourite_list_route')">
                <i class="icon icon-favourite"></i><span>{{ $t('frontend.member_favourite_list') }}</span>
                <small v-if="storeFav.length > 0" class="number-indicator active">{{
                    $root.$store.getters.getFav.length
                  }}</small>
              </a>
            </li>
            <li>
              <a v-bind:href="$root.$el.getAttribute('cart_route')">
                <i class="icon icon-cart"></i>
                <span>{{ $t('frontend.cart') }}</span>
                <small v-if="storeCart.length > 0"
                       class="number-indicator active">{{ $root.$store.getters.getCart.length }}</small>
              </a>
            </li>
          </template>
        </ul>
      </div>
    </div>

    <div class="container btmlayer d-none d-lg-block">
      <div class="row w-100 ml-2 mr-2 clamp">
        <i style="line-height: 40px; font-size: 18px; margin-left: 10px;">{{ $t('frontend.hot_search') }} : </i>
        <template v-for="(item, index) in hot_keyword.searches">
          <div v-if="item.search != ''" @click="copyToInput(item.search)" class="item hot-tag">
            <i class="icon icon-tag"></i>{{ item.search }}
          </div>
        </template>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import jquery from "jquery";
import CartMixin from "../../../cart/cart";
import algoliasearch from 'algoliasearch/lite';

export default {
  name: "desktop_header_bar",
  mixins: [CartMixin],
  components: {
    //'ais-instant-search' : InstantSearch
  },
  props: [
    'hot_searches',
  ],
  mounted() {
    const client = algoliasearch('8TF4WL5LWP', 'ddde8353236bcb52bddbb57345347e69');
    const index = client.initIndex('buygame2_products');
    let searchbox = document.querySelector('#algolia_product_search');
    let component = this;
    component.locale = this.$root.$i18n.locale;
    let f = function (e) {
      console.log('TE', e);
      index.search({
            query: e.target.value
          },
          (err, {hits} = {}) => {
            if (err) throw err;
            component.search_result = hits.slice(0, 6);  // first 6 result only
          }
      );
    };
    searchbox.addEventListener('input', f);
    //searchbox.addEventListener('click', f);
  },
  data: function () {
    return {
      base_path: '',
      product_types: null,
      member: null,
      login: false,
      searchClient: algoliasearch(
          '8TF4WL5LWP',
          'ddde8353236bcb52bddbb57345347e69'
      ),
      query: '',
      search_result: [],
      locale: 'en',
      hot_keyword: [],
    }
  },
  created: function () {
    this.base_path = base_path;
    this.product_types = choice_product_types;
    this.getLogin();
    this.getCart();
    this.getFav();

    //refresh after 2 hours...session will expire after 2 hours
    setTimeout(function () {
      location.reload();
    }, 7200000);
  },
  computed: {
    //get from store
    storeGetUser: function () {
      return this.$root.$store.getters.getUser;
    },
    storeIsLoggedIn: function () {
      return this.$root.$store.getters.isLoggedIn;
    },
    storeCart: function () {
      return this.$root.$store.getters.getCart;
    },
    storeFav: function () {
      return this.$root.$store.getters.getFav;
    }
  },
  watch: {
    //watch the data change and get it from store
    storeGetUser: function (new_val, old_val) {

    },
    storeIsLoggedIn: function (new_val, old_val) {

    },
    storeCart: function (new_val, old_val) {

    },
    storeFav: function (new_val, old_val) {

    },
    search_result: function (new_val, old_val) {

    },
    hot_searches: function (new_val, old_val) {
      this.hot_keyword = JSON.parse(new_val);
    }
  },
  methods: {
    onSelect(selected) {
      if (selected) {
        this.query = selected.item.name;
      }
    },
    indicesToSuggestions(indices) {
      return indices.map(({hits}) => ({data: hits}));
    },
    goToProductDetail(id) {
      //alert(id);
      window.location.replace(this.base_path + '/' + this.$root.$i18n.locale + '/product-details/' + id);
    },
    getLogin: function () {
      var route = this.$root.$el.getAttribute('get_member_route');
      var component = this;

      axios.post(route, {
        csrf_token: this.csrf_field
      }).then(function (response) {
        // this.endLoading();

        component.member = response.data.member;
        if (component.member != null) {
          component.login = true;
        }

        component.$root.$store.commit('changeUserStatus', {member: response.data.member, is_login: component.login});

        // console.log(component.$root.$store.getters.getUser);

      }).catch(function (error) {
        console.log(error);
      });
    },
    copyToInput(name) {
      $('#algolia_product_search').val(name);
      this.$refs.submit.click()
    },
    goSearch() {
      this.$refs.submit.click()
    }
  }
}
</script>

<style scoped>
.memberfeature span {
  font-size: 16px;
}

.username {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 125px;
  font-size: 16px;
  display: inline-block;
  height: 24px;
  line-height: 27px;
}

.hot-tag {
  cursor: pointer;
  font-size: 15px;
  background: #bfe9db;
  color: #707070;
  margin: 5px;
  padding: 5px 10px 5px 5px;
}

.more_result {
  text-align: center;
  line-height: 30px;
  width: 100%;
  cursor: pointer;
}
</style>