<template>
    <select name="currency" v-on:change="switchCurrency($event)">
        <option v-for="(currency, index) in currencies" v-bind:value="currency.id" v-bind:selected="use_currency.id === currency.id? 'selected' : ''">{{ currency.code }}</option>
    </select>
</template>

<script>
    import axios from "axios";

    export default {
        name: "currency_switcher",
        data: function(){
            return{
                currencies: null,
                use_currency: null,

                load_currency_route: null,
                switch_currency_route: null
            }
        },
        created: function(){
            this.currencies = choice_currency;
            this.use_currency = use_currency;
            this.load_currency_route = this.$root.$el.getAttribute('load_currency_route');
            this.switch_currency_route = this.$root.$el.getAttribute('switch_currency_route');
            this.loadData();
        },
        methods: {
            loadData: function(){
                var component = this;
                var route = this.load_currency_route;
                axios.post(route, {
                    // csrf_token: this.csrf_field,
                })
                    .then(function (response) {
                        component.use_currency = response.data;
                        use_currency = component.use_currency;
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                ;
            },
            switchCurrency: function($event){
                $event.preventDefault();
                var currency_id = $($event.target).val();
                var component = this;
                var route = this.switch_currency_route;

                axios.post(route, {
                    // csrf_token: this.csrf_field,
                    currency_id: currency_id
                })
                    .then(function (response) {
                        location.reload();
                    })
                    .catch(function (error) {
                        console.log(error);
                    })
                ;
            }
        }
    }
</script>