<template>
    <div>
        <ul class="mb-nav">
            <template v-if="storeIsLoggedIn === false">
                <!--<li><a v-bind:href="$root.$el.getAttribute('register_route')"><i class="icon icon-register"></i> <span>{{ $t('frontend.register') }}</span></a></li>-->
                <!--<li><a v-bind:href="$root.$el.getAttribute('login_route')"><i class="icon icon-login"></i> <span>{{ $t('frontend.login') }}</span></a></li>-->
                <li>
                    <a v-bind:href="$root.$el.getAttribute('favourite_list_route')">
                        <i class="icon icon-favourite"></i>
                        <span>{{ $t('frontend.favourite_list') }}</span>
                        <small v-if="storeFav.length > 0" class="number-indicator active">{{ $root.$store.getters.getFav.length }}</small>
                    </a>
                </li>
                <li>
                    <a v-bind:href="$root.$el.getAttribute('cart_route')">
                        <i class="icon icon-cart"></i>
                        <span>{{ $t('frontend.cart') }}</span>
                        <small v-if="storeCart.length > 0" class="number-indicator active">{{ $root.$store.getters.getCart.length }}</small>
                    </a>
                </li>
            </template>
            <template v-else>
                <li><a v-bind:href="$root.$el.getAttribute('member_page_route')"><i class="icon icon-login"></i> <span>{{ $t('frontend.member_page') }}</span></a></li>
                <li>
                    <a v-bind:href="$root.$el.getAttribute('member_favourite_list_route')">
                        <i class="icon icon-favourite"></i>
                        <span>{{ $t('frontend.member_favourite_list') }}</span>
                        <small v-if="storeFav.length > 0" class="number-indicator active">{{ $root.$store.getters.getFav.length }}</small>
                    </a>
                </li>
                <li>
                    <a v-bind:href="$root.$el.getAttribute('cart_route')">
                        <i class="icon icon-cart"></i>
                        <span>{{ $t('frontend.cart') }}</span>
                        <small v-if="storeCart.length > 0" class="number-indicator active">{{ $root.$store.getters.getCart.length }}</small>
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script>
    import jquery from "jquery";

    export default {
        name: "mobile_cart_widget",
        props: [

        ],
        data: function(){
            return {
                member: null,
                login: false,
            }
        },
        computed:{
            //get from store
            storeGetUser: function(){
                return this.$root.$store.getters.getUser;
            },
            storeIsLoggedIn: function(){
                return this.$root.$store.getters.isLoggedIn;
            },
            storeCart: function(){
                return this.$root.$store.getters.getCart;
            },
            storeFav: function(){
                return this.$root.$store.getters.getFav;
            }
        },
        watch: {

        },
        created: function(){
        }
    }
</script>