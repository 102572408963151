import axios from "axios";
import store from "../../store/main_store";
import VueInternationalization from 'vue-i18n';
import lineClamp from 'vue-line-clamp';
import trans from '../../../js/vue-i18n-locales.generated';
import moment from "vue-moment";

import MomentDurationFormat from "moment-duration-format";
import DesktopHeaderBar from "./wrapper/DesktopHeaderBar";
import MobileHeaderBar from "./wrapper/MobileHeaderBar";
import MobileCartWidget from "./wrapper/MobileCartWidget";
import AdvancedSearch from "./wrapper/AdvancedSearch";
import CurrencySwitcher from "./wrapper/CurrencySwitcher";
import CurrencySwitcherCopy from "./wrapper/CurrencySwitcherCopy";
import { ToastPlugin } from 'bootstrap-vue';
import InstantSearch from 'vue-instantsearch';

// MomentDurationFormat(moment);

Vue.use(VueInternationalization);
Vue.use(moment);
Vue.use(lineClamp);
Vue.use(ToastPlugin);
Vue.use(InstantSearch);

const i18n = new VueInternationalization({
    locale: use_locale,
    messages: trans
});

var desktop_header_bar = new Vue({
    el: '#desktop_header_bar',
    i18n,
    store,
    data: {
        hot_searches: [],
    },
    components: {
        desktop_header_bar: DesktopHeaderBar
    },
    created: function(){

    },
    mounted: function(){
        this.hot_searches = this.$el.getAttribute('hot_searches');
    }
});

var mobile_header_bar = new Vue({
    el: '#mobile_header_bar',
    i18n,
    store,
    data: {

    },
    components: {
        mobile_header_bar: MobileHeaderBar
    },
});

// var mobile_cart_widget = new Vue({
//     el: '#mobile_cart_widget',
//     i18n,
//     store,
//     data: {
//
//     },
//     components: {
//         mobile_cart_widget: MobileCartWidget
//     },
// });

var advanced_search = new Vue({
    el: '#search-adv',
    i18n,
    data: {
        hot_searches: [],
    },
    components: {
        advanced_search: AdvancedSearch
    },
    mounted: function(){
        this.hot_searches = this.$el.getAttribute('hot_searches');
    }
});

var currency_switcher = new Vue({
    el: '#currency_switcher',
    i18n,
    data: {

    },
    components: {
        currency_switcher: CurrencySwitcher
    }
});

window.onload = function () {
    var currency_switcher2 = new Vue({
        el: '#currency_switcher2',
        i18n,
        data: {},
        components: {
            currency_switcher: CurrencySwitcher
        }
    });
}

var currency_switcher_mobile = new Vue({
    el: '#currency_switcher_mobile',
    i18n,
    data: {

    },
    components: {
        currency_switcher: CurrencySwitcher
    }
});

$(function () {

    $('.advanced-search-criteria').each(function () {
        var element = $(this);
        $(element).select2();
    });

    $('.advanced-search-criteria-rules').select2({});

    $('.advanced-search-criteria-sort').select2({});

    $('.advanced-search-criteria-product-type').select2({});

    $('.adv-search-air-datepicker').datepicker(
        {
            onSelect: function(formatted_date, date, instance){
                var dates = formatted_date.split(' - ');
                console.log(dates);

                console.log('dates');
                if(dates.length === 1) {
                    $('#search_input_product_create_date_from').val(dates[0]);
                    $('#search_input_product_create_date_to').val(null);
                } else if(dates.length === 2){
                    $('#search_input_product_create_date_from').val(dates[0]);
                    $('#search_input_product_create_date_to').val(dates[0]);
                } else if(dates.length === 0){
                    $('#search_input_product_create_date_from').val(null);
                    $('#search_input_product_create_date_to').val(null);
                }
            }
        }
    );


});