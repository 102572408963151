<template>
    <div>
        <div class="search-adv-head d-flex justify-content-center">
            <div class="row container">
                <div class="container col-10 p-0">
                    <div class="search d-flex flex-wrap">
                        <!-- No use -->
                        <!--<div class="item">Will U <i class="icon icon-close"></i></div>-->
                        <!--<div class="item">遊戲 <i class="icon icon-close"></i></div>-->
                        <!--<div class="item">動作 <i class="icon icon-close"></i></div>-->
                        <!--<div class="item">香港版 <i class="icon icon-close"></i></div>-->
                        <!--<div class="item">中文 <i class="icon icon-close"></i></div>-->
                        <!--<div class="item">大亂鬥 <i class="icon icon-close"></i></div>-->
                    </div>
                </div>
                <div class="col-2 p-0 d-flex justify-content-end">
                    <button type="button" class="search-adv-btn"><i class="icon icon-close"></i></button>
                </div>
            </div>
        </div>

        <form v-bind:action="$root.$el.getAttribute('product_list_route')" method="GET">

            <div class="container">
                <div class="search-advance">
                    <i class="icon-search"></i><span class="search-box">{{ $t('frontend.advanced_search') }}</span>
                    <!--<input type="text" id="search-box" class="search-box" v-bind:placeholder="$t('frontend.advanced_search')">-->
                </div>
                <!--<div class="top-banner d-flex flex-wrap">-->
                    <!--<div class="item active">{{ $t('frontend.games') }}</div>-->
                    <!--<div class="item" style="visibility: hidden;">{{ $t('frontend.household_goods') }}</div>-->
                    <!--<div class="item" style="visibility: hidden;">{{ $t('frontend.digital_goods') }}</div>-->
                    <!--<div class="item" style="visibility: hidden;">{{ $t('frontend.boutiques') }}</div>-->
                <!--</div>-->
                <div class="voice-search">
                    <div class="voice-search-container">
                        {{ $t('frontend.keyword') }} : <input type="text" name="search_input_product_name" id="voice-search-box" class="voice-search-box">
                        <button class="the-voice"><i class="icon-search"></i></button>
                    </div>
                </div>
                <div class="hot-search">
                    {{ $t('frontend.hot_search') }}
                    <template v-for="(item, index) in hot_keyword.searches">
                        <div v-if="item.search != ''" @click="copyToInput(item.search)" class="item" style="cursor: pointer"><i class="icon icon-tag"></i>{{item.search}}</div>
                    </template>
                </div>
                <hr>
                <!-- -->
             <!-- -->
                <div class="row justify-content-around">
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.products_console') }}</div>
                                </div>
                                <div class="col-12">
                                    <select name="search_input_product_type" id="search_input_product_type" class="advanced-search-criteria-product-type"
                                    >
                                        <option value="all">{{ $t('frontend.product_all') }}</option>
                                        <template v-for="(level1, index1) in product_types">
                                            <option v-bind:value="level1.id" class="product-type-level1 unselectable">{{ level1.text }}</option>
                                            <!--<template v-for="(level2, index2) in level1.children">-->
                                                <!--&lt;!&ndash;If&ndash;&gt;-->
                                                <!--<template v-if="level2.children.length > 0">-->
                                                    <!--<option v-bind:value="level2.id"  class="product-type-level2 unselectable">&#45;&#45;&#45;&#45;{{ level2.text }}</option>-->
                                                    <!--<option v-for="(level3, index3) in level2.children" v-bind:value="level3.id">&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;{{ level2.text }} {{ level3.text }}</option>-->
                                                <!--</template>-->

                                                <!--&lt;!&ndash;Else&ndash;&gt;-->
                                                <!--<option v-else v-bind:value="level2.id">&#45;&#45;&#45;&#45;{{ level2.text }}</option>-->
                                            <!--</template>-->
                                        </template>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->

                    <!-- -->
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.products_genre') }}</div>
                                </div>
                                <div class="col-12">
                                    <select name="search_input_product_genre" id="search_input_product_genre" class="advanced-search-criteria"

                                    >
                                        <option value="all">{{ $t('frontend.product_all') }}</option>
                                        <option v-for="(item, index) in genres" v-bind:value="item.id">{{ item.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->

                    <!-- -->
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.products_version') }}</div>
                                </div>
                                <div class="col-12">
                                    <select name="search_input_product_version" id="search_input_product_version" class="advanced-search-criteria"

                                    >
                                        <option value="all">{{ $t('frontend.product_all') }}</option>
                                        <option v-for="(item, index) in versions" v-bind:value="item.id">{{ item.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->

                    <!-- -->
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.products_language') }}</div>
                                </div>
                                <div class="col-12">
                                    <select name="search_input_product_gamelang" id="search_input_product_gamelang" class="advanced-search-criteria"

                                    >
                                        <option value="all">{{ $t('frontend.product_all') }}</option>
                                        <option v-for="(item, index) in gamelangs" v-bind:value="item.id">{{ item.name }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->
                </div>

                <div class="row justify-content-around">
                    <!-- -->
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.products_stock_label') }}</div>
                                </div>
                                <div class="col-12">
                                    <select name="search_input_product_label" id="search_input_product_label" class="advanced-search-criteria"
                                    >
                                        <option value="all">{{ $t('frontend.products_stock_all') }}</option>
                                        <option value="1">{{ $t('frontend.products_stock_1') }}</option>
                                        <option value="2">{{ $t('frontend.products_stock_2') }}</option>
                                        <option value="3">{{ $t('frontend.products_stock_3') }}</option>
                                        <option value="4">{{ $t('frontend.products_stock_4') }}</option>
                                        <option value="5">{{ $t('frontend.products_stock_5') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->

                    <!-- -->
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.products_number_player_online') }}</div>
                                </div>
                                <div class="col-12">
                                    <select name="search_input_product_number_player_online" id="search_input_product_number_player_online" class="advanced-search-criteria"
                                    >
                                        <option value="all">{{ $t('frontend.product_all') }}</option>
                                        <option value="1">{{ $t('frontend.products_number_players_1') }}</option>
                                        <option value="2">{{ $t('frontend.products_number_players_2') }}</option>
                                        <option value="3">{{ $t('frontend.products_number_players_3') }}</option>
                                        <option value="4">{{ $t('frontend.products_number_players_4') }}</option>
                                        <option value="5">{{ $t('frontend.products_number_players_5') }}</option>
                                        <option value="6">{{ $t('frontend.products_number_players_6') }}</option>
                                        <option value="7">{{ $t('frontend.products_number_players_7') }}</option>
                                        <option value="10">{{ $t('frontend.products_number_players_10') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->

                    <!-- -->
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.products_number_player_offline') }}</div>
                                </div>
                                <div class="col-12">
                                    <select name="search_input_product_number_player_offline" id="search_input_product_number_player_offline" class="advanced-search-criteria"
                                    >
                                        <option value="all">{{ $t('frontend.product_all') }}</option>
                                        <option value="1">{{ $t('frontend.products_number_players_1') }}</option>
                                        <option value="2">{{ $t('frontend.products_number_players_2') }}</option>
                                        <option value="3">{{ $t('frontend.products_number_players_3') }}</option>
                                        <option value="4">{{ $t('frontend.products_number_players_4') }}</option>
                                        <option value="5">{{ $t('frontend.products_number_players_5') }}</option>
                                        <option value="6">{{ $t('frontend.products_number_players_6') }}</option>
                                        <option value="7">{{ $t('frontend.products_number_players_7') }}</option>
                                        <option value="10">{{ $t('frontend.products_number_players_10') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->

                    <!-- -->
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.search_additional_rules') }}</div>
                                </div>
                                <div class="col-12">
                                    <select name="search_input_additional_rules" id="search_input_additional_rules" class="advanced-search-criteria-rules"

                                    >
                                        <option value="null">{{ $t('frontend.product_search_default') }}</option>
                                        <option value="1">{{ $t('frontend.search_criteria_newest_sales') }}</option>
                                        <option value="2">{{ $t('frontend.search_criteria_buy_from_shop') }}</option>
                                        <option value="3">{{ $t('frontend.search_criteria_sales_price') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -->

                </div>

                <div class="row justify-content-left">

                    <!-- -->
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.search_sorting_criteria') }}</div>
                                </div>
                                <div class="col-12">
                                    <select name="search_input_sorting_criteria" id="search_input_sorting_criteria" class="advanced-search-criteria-sort"

                                    >
                                        <option value="3">{{ $t('frontend.search_criteria_create_date') }}</option>
                                        <option value="1">{{ $t('frontend.search_criteria_sort_A-Z') }}</option>
                                        <option value="2">{{ $t('frontend.search_criteria_sort_Z-A') }}</option>
                                        <option value="4">{{ $t('frontend.search_criteria_product_price') }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.products_arrival_date') }}</div>
                                </div>
                                <div class="col-12 text-center">
                                    <input type="text"
                                           data-range="true"
                                           data-date-format="yyyy-mm-dd"
                                           data-multiple-dates-separator=" - "
                                           data-language="en"
                                           class="adv-search-air-datepicker"
                                           readonly
                                           v-bind:placeholder="$t('frontend.search_arrival_date_placeholder')"
                                    >
                                    <input type="hidden" name="search_input_product_create_date_from" id="search_input_product_create_date_from">
                                    <input type="hidden" name="search_input_product_create_date_to" id="search_input_product_create_date_to">
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- -->
                    <div class="col-lg-3">
                        <div class="search-w-box">
                            <div class="row">
                                <div class="col-12">
                                    <div class="swbox-title">{{ $t('frontend.products_price') }}</div>
                                </div>
                                <div class="col-12">
                                    <!--<input type="text" id="adv-search-amount"-->
                                           <!--name="search_input_product_price"-->
                                           <!--style="border:0;background: #eee;padding: 0 10px;"-->
                                           <!--v-bind:placeholder="$t('frontend.search_input_placeholder')"-->
                                    <!--&gt;-->
                                    <div class="range-slider">
                                        <input type="text" id="amount2"
                                               style="border:0;background: #eee;padding: 0 10px;"
                                               v-bind:placeholder="$t('frontend.search_input_placeholder')"
                                               name="search_input_product_price" @input="showPrice($event)">

                                        <div style="margin-left:5px">OR</div>

                                        <input class="range-slider__range" type="range" value="10" min="0" max="500"
                                               id="adv-search-amount"
                                               @input="showPrice($event)" @change="showPrice($event)">
                                        <span class="range-slider__value">0</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12" style="text-align: right">
                        <button type="submit" class="btn btn-blue search-adv-btn-submit" id="search-adv-btn" style="background-color: #3A95B2;     padding: 5px 18px;">
                            {{ $t('frontend.search') }}
                        </button>
                    </div>
                </div>

            </div>
        </form>
    </div>
</template>

<script>
    export default {
        name: "advanced_search",
        props: [
            'hot_searches',
        ],
        data: function(){
            return {
                gamelangs: null,
                versions: null,
                genres: null,
                product_types: null,
                hot_keyword: []
            }
        },
        watch: {
            hot_searches: function(new_val, old_val){
                this.hot_keyword = JSON.parse(new_val);
            },
        },
        created: function(){
            this.gamelangs = choice_gamelangs;
            this.versions = choice_versions;
            this.genres = choice_genres;
            this.product_types = choice_product_types;
        },
        methods: {
            showPrice(e) {
                if(e.target.value != ''){
                    $('.range-slider__value').html(e.target.value);
                    $('.range-slider__range').val(e.target.value);
                    document.getElementById('amount2').value = e.target.value;
                }else{
                    $('.range-slider__value').html(0);
                    $('.range-slider__range').val(0);
                    document.getElementById('amount2').value = 0;
                }
            },
            copyToInput: function(name){
                $('#voice-search-box').val(name);
            }
        }
    }
</script>