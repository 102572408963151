<template>
    <div>
        <template v-if="storeIsLoggedIn === false">
            <a v-bind:href="$root.$el.getAttribute('login_route')" v-bind:title="$t('frontend.account')"><i class="icon icon-login"></i></a>
            <a v-bind:href="$root.$el.getAttribute('favourite_list_route')">
                <i class="icon icon-favourite"></i>
                <small v-if="storeFav.length > 0" class="number-indicator active">{{ $root.$store.getters.getFav.length }}</small>
            </a>
            <a v-bind:href="$root.$el.getAttribute('cart_route')">
                <i class="icon icon-cart"></i>
                <small v-if="storeCart.length > 0" class="number-indicator active">{{ $root.$store.getters.getCart.length }}</small>
            </a>
        </template>
        <template v-else>
            <a v-bind:href="$root.$el.getAttribute('member_page_route')" v-bind:title="$t('frontend.member_page')"><i class="icon icon-login"></i></a>
            <a v-bind:href="$root.$el.getAttribute('member_favourite_list_route')">
                <i class="icon icon-favourite"></i>
                <small v-if="storeFav.length > 0" class="number-indicator active">{{ $root.$store.getters.getFav.length }}</small>
            </a>
            <a v-bind:href="$root.$el.getAttribute('cart_route')">
                <i class="icon icon-cart"></i>
                <small v-if="storeCart.length > 0" class="number-indicator active">{{ $root.$store.getters.getCart.length }}</small>
            </a>
        </template>
    </div>
</template>

<script>
    import jquery from "jquery";

    export default {
        name: "mobile_header_bar",
        props: [

        ],
        data: function(){
            return {
                member: null,
                login: false,
            }
        },
        computed:{
            //get from store
            storeGetUser: function(){
                return this.$root.$store.getters.getUser;
            },
            storeIsLoggedIn: function(){
                return this.$root.$store.getters.isLoggedIn;
            },
            storeCart: function(){
                return this.$root.$store.getters.getCart;
            },
            storeFav: function(){
                return this.$root.$store.getters.getFav;
            }
        },
        watch: {
            storeGetUser: function(new_val, old_val){
                this.member = new_val;
            },
            storeIsLoggedIn: function(new_val, old_val){
                this.login = new_val;
            }
        },
        created: function(){
        }
    }
</script>